<template>
  <div class="newsletter">
    <h2 class="newsletter__header">Få biltorvets nyhedsbrev</h2>
    <p class="newsletter__description">
      Bliv opdateret med de nyeste annoncer, og få spændende inspiration i form
      af artikler, guides og anmeldelser. Du kan altid afmelde dette.
    </p>

    <div class="newsletter__inputWrapper">
      <div class="newsletter__acceptance">
        <p class="error my-1" v-if="termsErrorMessage">
          <span>{{ termsErrorMessage }}</span>
          <span class="error__attention"></span>
        </p>
        <div class="checkbox my-1">
          <input
            aria-hidden="true"
            type="checkbox"
            id="nlAccept"
            v-model="termsAccepted"
          />
          <label for="nlAccept" class="my-1 inline-flex">
            <span class="input-visual"></span>
            <p class="newsletter__acceptanceText">
              Ved tilmelding accepterer jeg Biltorvets
              <a href="/bruger/betingelser">betingelser</a>
            </p>
          </label>
        </div>
      </div>

      <div class="newsletter__input-outer-wrapper">
        <div class="newsletter__wrapper">
          <p class="error my-1" v-if="emailErrorMessage">
            <span>{{ emailErrorMessage }}</span>
            <span class="error__attention"></span>
          </p>
          <div class="input-container">
            <input
              title="Skriv din emailadresse"
              class="newsletter__emailInput error--input form-control"
              id="newsletterEmail"
              type="text"
              placeholder="Skriv din emailadresse"
              v-model="emailAddress"
              autocomplete="off"
            />
            <label class="control-label" for="newsletterEmail">Email</label>
          </div>
        </div>
        <div class="newsletter__wrapper newsletter__button-wrapper">
          <button
            type="button"
            class="newsletter__submit button"
            @click="signUp()"
          >
            tilmeld
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { app } from '@/main'

export default {
  data() {
    return {
      termsErrorMessage: '',
      emailErrorMessage: '',
      emailAddress: '',
      termsAccepted: false,
      termsErrorTriggered: false,
      emailErrorTriggered: false,
    }
  },
  methods: {
    clear() {
      this.clearErrors()
      this.emailAddress = ''
      this.termsAccepted = false
    },
    clearErrors() {
      this.emailErrorMessage = ''
      this.termsErrorMessage = ''
    },
    hasErrors() {
      return this.emailErrorTriggered || this.termsErrorTriggered
    },
    clearTriggers() {
      this.termsErrorTriggered = false
      this.emailErrorTriggered = false
    },
    signUp() {
      if (this.emailAddress == '') {
        this.emailErrorMessage = 'Angiv gyldig email'
        this.emailErrorTriggered = true
      }
      if (!this.termsAccepted) {
        this.termsErrorMessage = 'Acceptér betingelserne'
        this.termsErrorTriggered = true
      }
      if (this.emailAddress != '' && this.termsAccepted) {
        this.clearErrors()
      } else {
        return
      }

      const owner = this
      // AXIOS til server
      app.axios
        .post('/Newsletter/AddSubscriber', { email: this.emailAddress })
        .then(resp => {
          if (
            resp.data.errorCollection &&
            resp.data.errorCollection.length > 0
          ) {
            resp.data.errorCollection.forEach(value => {
              if (value.errorItem === 'email') {
                owner.emailErrorMessage = value.errorMessage
              }
            })
          } else {
            this.clear()
            app.toast.success('Du er nu tilmeldt Biltorvets nyhedsbrev')
          }
        })
    },
  },
  watch: {
    termsAccepted() {
      if (this.termsErrorTriggered) {
        this.termsErrorMessage = ''
        this.termsErrorTriggered = false
      }
    },
    emailAddress() {
      if (this.emailErrorTriggered) {
        this.emailErrorMessage = ''
        this.emailErrorTriggered = false
      }
    },
  },
}
</script>

<style lang="scss">
@import 'Newsletter.scss';
</style>
